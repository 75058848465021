// Exam parts imported through exams_controller
const exam_json = gon.js_exam_parts;


// Vimeo player options
import {stopRecording, stopPrevRecording, startNewRecording } from '../packs/init-web-audio-recorder';
import { folder_create } from './audio_uploader';


function VimeoVideoPlayer() {

    const options = (index = 0) => {
        return{
            url: exam_json[index][0],
            autoplay: false,
            controls: false,
            loop: false,
            portrait: false,
            responsive: true,
            title: false
        };
    }

    // Initialize the player
    const vimeoPlayerData = document.getElementById("player_container").dataset;
    const exam_part_title = document.getElementById("part-end-modal-title");
    const exam_part_description = document.getElementById("part-end-modal-body");
    const playButton = document.getElementById("start_button");
    const next_part_button = document.getElementById("next_part_button");
    const zoomButton = document.getElementById("zoom_button");
    const update_status_button = document.getElementById("update_status");

    window.folder_id = false

    // When page loads, display the test start modal
    $("#TestStart").modal({
        backdrop: "static",
        keyboard: false,
    });


    // examVimeoPlayer.on('ended', () => {
    //   currentVideoIndex++;
    //   if (currentVideoIndex < videoPlaylist.length) {
    //     const nextVideoId = videoPlaylist[currentVideoIndex];
    //     // Load and play the next video
    //     examVimeoPlayer.loadVideo(nextVideoId).then(() => {
    //       examVimeoPlayer.play();
    //     });
    //   }
    // });

    var currentSavedPart = (gon?.test_progress?.last_finished ?? 0)
    currentSavedPart = currentSavedPart < 0 ? 0 : currentSavedPart
    if(Object.keys(exam_json)?.length <= currentPartIndex){
        currentSavedPart = 0;
    }
    vimeoPlayerData.currentPart = currentSavedPart
    console.log("Vimeo player started")

    var examVimeoPlayer;
    const viemoFunc = (ab = true) => {
        if(ab) {
            const currentIndex = vimeoPlayerData.currentPart
            examVimeoPlayer = new Vimeo.Player("exam_player", options(vimeoPlayerData.currentPart));

            console.log("display modal and cue next video");
            var currentPart = exam_json[currentIndex];
            examVimeoPlayer.loadVideo(currentPart[0]); 
            
            exam_part_title.innerHTML = currentPart[1];
            console.log(exam_part_title.innerHTML);
            exam_part_description.innerHTML = currentPart[2];
            console.log(exam_part_description.innerHTML);
            playButton.style.display = "none";

            var duration = false
            let loaded = false 

            examVimeoPlayer.on("loaded", function() {
                play_part()
                console.log("Video has laoded");
                loaded = true    

                examVimeoPlayer.getDuration().then(function(dur) {
                    console.log("Video duration: " + dur + " seconds");
                    duration = dur
                }).catch(function(error) {
                    console.error("Error getting video duration: " + error.message);
                });

                
                let recording_interval;
                let recorder_started = false;

                recording_interval = setInterval(() => {
                    if(duration < 40){
                        return;
                    }
                    examVimeoPlayer.getCurrentTime().then(function(curr_dur) {
                        // `duration` indicates the duration of the video in seconds
                        console.log("CURRENT DURATION IS " + curr_dur, "VIDEO LEFT " + (duration - curr_dur));
                        if (duration - curr_dur < 128 && !recorder_started) {
                            console.log("RECORDING STARTED");
                            startNewRecording(currentIndex, currentIndex+1);
                            recorder_started = true;
                            clearInterval(recording_interval);
                        }
                    });
                }, 1000);

            });

            var started = false
            examVimeoPlayer.on("play", function() {
                if(!started){
                    console.log("Video has started playing");
                }
                started = true
            });
        }else {
            examVimeoPlayer = new Vimeo.Player("exam_player", options(vimeoPlayerData.currentPart));
        }

        // Event for video ending
        examVimeoPlayer.on("ended", function () {
            // video ended
            // Increase currentPart +1
            vimeoPlayerData.currentPart = parseInt(vimeoPlayerData.currentPart) + 1;

            // If the current part index is less than the exam length, display the modal, load the next video and change the video info.
            if (parseInt(vimeoPlayerData.currentPart) < Object.keys(exam_json).length) {

                stopPrevRecording()
                examVimeoPlayer.destroy()
                viemoFunc()
                console.log("ASDFASDF");

            // If there are no more parts, display the end modal.
            } else {
                examVimeoPlayer.destroy()
                stopRecording();
                console.log("the exam is finished");
                $("#upload_completed_part").text("0 / " + Object.keys(exam_json).length)
                $("#EndModal-Loader").modal({
                    backdrop: "static",
                    keyboard: false,
                });
            }
        });


        examVimeoPlayer.on('pause', function(event) {
            examVimeoPlayer.play(); // Automatically play the video again when paused
        });
    }
    viemoFunc(false)

    // When play button is pressed, start video and set volume to max.
    playButton.addEventListener("click", async function(e) {

        const not_detected = () => {
            alert("No microphone detected");
            setTimeout(() => {
                $("#TestStart").modal({
                    backdrop: "static",
                    keyboard: false,
                });
            }, 1000);
            document.getElementById("start_button").disabled = true;
        }

        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            if(!stream){
                not_detected();
                return;
            }
        } catch (error) {
            not_detected();
            return;
        }

        window.test_started = true;
        try {
            if(window.test_media_recorder){
                if(typeof window.test_media_recorder?.stop == "function"){
                    window.test_media_recorder?.stop()
                }
                if(typeof window.test_audio_player?.play == "function"){
                    window.test_audio_player?.pause()
                    URL.revokeObjectURL(window.test_audio_player_url);
                }
            }
            // if (window.test_audioContext) {
            //     window.test_audioContext.close();
            //     window.test_microphone = null;
            //     window.test_audioDestination = null;
            // }
        } catch (error) {
            console.log(error)
        }
        play_part(e)
        const new_el = $("<div class='overlay-nav'></div>")
        $(".navbar.navbar-comas").append(new_el)
        folder_create(function(data) {
            console.log("FOLDER CREATED", data);
        })
    });
    // next_part_button.addEventListener("click", function(e) {
    //   play_part(e)
    //   stopPrevRecording()
    // });

    function play_part(event) {
        // event.preventDefault();
        examVimeoPlayer.play();
        examVimeoPlayer.setVolume(1);
    }

    function playDirectly(){
        examVimeoPlayer.play();
        examVimeoPlayer.setVolume(1);
        stopPrevRecording()
    }

    // 
    update_status_button.addEventListener("click", show_upload);
    function show_upload(){
        $("#EndModal-1").modal('hide');
        $("#EndModal-2").modal({
            backdrop: "static",
            keyboard: false,
        });
    };

    // zoom button player

    zoomButton.addEventListener("click", function () {
        document.querySelector(".video-card").classList.toggle('video-card-zoom');
        zoomButton.innerText = "拡大 / Maximize"
        const zoomText = zoomButton.innerText;
        zoomButton.addEventListener("click", function () {
            if (this.innerText == zoomText) {
            zoomButton.innerText = "縮小 / Minimize";
            } else {
            zoomButton.innerText = zoomText;
            }
        })
    });

}


export default VimeoVideoPlayer